import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const MyProxyAddCard = styled.div`
  padding: 0 10px 0 10px;
  flex: none;
  display: block;
  margin: 0px auto;

  @media (max-width: 960px) {
    width: 100%;
    padding: 0;
  }
  @media (min-width: 961px) and (max-width: 1060px) {
    width: 46%;
  }
  @media (min-width: 1061px) {
    width: 31%;
  }

  .a-container {
    text-decoration: none;
  }

  .card-container:hover {
    box-shadow: #d7e1e4 0px 0px 0px 2px,
      rgba(106, 191, 131, 0.1) 0px 0px 0px 1px,
      rgba(106, 191, 131, 0.1) 0px 30px 90px 0px,
      rgba(158, 158, 163, 0.25) 0px 2px 18px 0px;
  }
  .card-container {
    box-shadow: rgba(65, 155, 249, 0.1) 0px 0px 0px 1px,
      rgba(65, 155, 249, 0) 0px 0px 0px 0px,
      rgba(65, 155, 249, 0.08) 0px 12px 50px 0px,
      rgba(158, 158, 163, 0.25) 0px 2px 10px 0px;
    background-color: rgb(255, 255, 255);
    will-change: transform, box-shadow;
    transform: matrix(1, 0, 0, 1, 0, 0);
    display: block;
    padding: 15px 0;
    position: relative;
    min-height: 284px;
    margin: 0 auto 24px;
    border-radius: 12px;
  }

  .ccc1 {
  }

  .ccc1a {
    padding: 0 15px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
  }

  .ccc1a-span {
    margin-right: 9px;
  }

  .ccc1as-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    overflow: hidden;
    position: relative;
  }

  .ccc1asd-1 {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
  }

  .ccc1asd-1a {
    display: block;
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    position: relative;
  }

  .renew-proxy-img {
    height: 100%;
  }
  .ccc1a-para {
    color: #9ba2ac;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    margin: 0;
    padding: 0;
  }

  .ccc1b {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }

  .ccc1b-img-div {
    margin: 20px auto;
    width: auto;
    display: table;
    position: relative;
  }

  .ccc1bid-img {
    height: 70px;
  }

  .ccc1b-input {
    color: #419bf9;
    width: 60%;
    padding: 0 10px;
    margin-bottom: 1px;
    margin-top: 0px;
    line-height: 24px;
    font-weight: 700;
    text-align: center;
    overflow: hidden;
    border: 1px solid transparent;
    font-size: 18px;
  }

  .tooltip-container-div {
    min-height: 20px;
    max-height: 20px;
    margin: 0px 40px;
  }
`

const MyManageButton = styled.button`
  background-color: #667af4;
  font-size: 11px;
  color: white;
  height: 40px;
  width: 110px;
  border-radius: 8px;
  border: 0;
  display: table;
  margin: 30px auto 10px auto;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
`

export default function ProxyAddCard(props) {
  const { logoLink } = props

  return (
    <MyProxyAddCard>
      <div className="card-container">
        <div className="ccc1">
          <div className="ccc1a">
            <span className="ccc1a-span">
              <div className="ccc1as-div">
                <div className="ccc1asd-1">
                  <div className="ccc1asd-1a"></div>
                </div>
              </div>
            </span>
          </div>
          <div className="ccc1b-img-div">
            <img className="ccc1bid-img" src={logoLink} alt="horse-logo"></img>
          </div>
        </div>
        <div className="ccc1b">
          <p className="ccc1b-input">Your First Proxy</p>
        </div>

        <Link to="/addaproxy" style={{ textDecoration: "none" }}>
          <MyManageButton>add a proxy</MyManageButton>
        </Link>
      </div>
    </MyProxyAddCard>
  )
}

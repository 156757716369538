import React from "react"
import styled from "styled-components"

import ProxyBigCard from "./ProxyBigCard"
import ProxyListCard from "./ProxyListCard"
import ProxyAddCard from "./ProxyAddCard"
import CryptoActValidationModal from "./CryptoActValidationModal"
import authStore from "../stores/auth"
import { useState } from "react"
import { get } from "lodash"

const MyProxiesApp = styled.div`
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: 960px) {
    margin: 0 30px;
    flex-wrap: flex;
    flex-direction: column;
  }
  @media screen and (min-width: 961px) {
    flex-wrap: wrap;
    flex-direction: row;
  }
`

const proxyLogoLinks = [
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188729/rock%20proxies%20images/rock_proxies_images_Group_166_hlvewa.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188729/rock%20proxies%20images/rock_proxies_images_Group_165_hbdnb4.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_161_bpfal1.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_163_mj4ttt.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_164_bqrjxr.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_162_jdpbuk.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_160_pzi23s.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_157_tkkv60.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_158_tjebwt.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_159_koyrpp.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188728/rock%20proxies%20images/rock_proxies_images_Group_156_qgqdmf.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_155_wzapor.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_153_vea0nb.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_154_tcyu0s.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_147_eizmah.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_152_n6a7go.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_146_eewfms.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_145_wpt16k.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_150_or0auz.png",
  "https://res.cloudinary.com/dd-sc-am1/image/upload/v1586188727/rock%20proxies%20images/rock_proxies_images_Group_151_pnw0u1.png",
]

export default function ProxiesApp({
  isMobile,
  proxiesPageCardSize,
  allSubscriptions,
  updateNickOfThisSubscription,
  setRecurringSubscriptionCryptomus,
}) {
  const [activatingRecurring, setActivatingRecurring] = useState(false)
  const [subscriptionId, setSubscriptionId] = useState(null)
  const [cryptoActivationModal, setCryptoActivationModal] = useState(false)
  const mpEmail = get(authStore, "state.user.email", "email not found")

  const onCryptoActValidateSuccess = () => {
    onCryptomusRecurringSubscription()
    setCryptoActivationModal(false)
  }

  const handleActiveCryptomusRecurring = subscriptionId => {
    setSubscriptionId(subscriptionId)
    setCryptoActivationModal(true)
  }

  const onCryptomusRecurringSubscription = async () => {
    try {
      setActivatingRecurring(true)
      const response = await setRecurringSubscriptionCryptomus(
        subscriptionId,
        mpEmail
      )
      if (response && response.checkout_url) {
        localStorage.setItem("paymentType", "addCredit")
        window.location.href = response.checkout_url
      } else {
        setActivatingRecurring(false)
        setSubscriptionId(null)
      }
      return
    } catch (err) {
      console.error("[Error]", err)
      setActivatingRecurring(false)
      setSubscriptionId(null)
    }
  }

  return (
    <MyProxiesApp>
      {allSubscriptions.length === 0 && (
        <ProxyAddCard logoLink={proxyLogoLinks[0]} />
      )}

      {allSubscriptions.map((elem, idx) => {
        // @TODO: Replace these two with a single responsive component
        if (isMobile === true || proxiesPageCardSize === "large") {
          return (
            <ProxyBigCard
              key={elem.id}
              logoLink={proxyLogoLinks[idx % proxyLogoLinks.length]}
              updateNickOfThisSubscription={updateNickOfThisSubscription}
              {...elem}
              handleActiveCryptomusRecurring={handleActiveCryptomusRecurring}
              activatingRecurring={activatingRecurring}
            />
          )
        }

        return (
          <ProxyListCard
            key={elem.id}
            updateNickOfThisSubscription={updateNickOfThisSubscription}
            {...elem}
            handleActiveCryptomusRecurring={handleActiveCryptomusRecurring}
            activatingRecurring={activatingRecurring}
          ></ProxyListCard>
        )
      })}
      <CryptoActValidationModal
        isOpen={cryptoActivationModal}
        paymentMethod={"Cryptomus"}
        mpEmail={mpEmail}
        onHide={() => setCryptoActivationModal(false)}
        onSubmit={onCryptoActValidateSuccess}
      />
    </MyProxiesApp>
  )
}

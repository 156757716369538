import React from "react"
import styled from "styled-components"

import largeCardsGrey from "../imgs/large-cards-grey.svg"
import largeCardsBlue from "../imgs/large-cards-blue.svg"
import listCardsGrey from "../imgs/list-cards-grey.svg"
import listCardsBlue from "../imgs/list-cards-blue.svg"

import reload from "../imgs/reload.svg"

const MyDesktopHeader = styled.header`
  // hide the desktop header on mobile devices
  @media screen and (max-width: 960px) {
    display: none;
  }

  padding: 20px 20px 20px 0;
  min-height: 45px;

  .header-child {
    max-width: 930px;
    width: 100%;
    margin: 0 auto;
  }

  .hc-child {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .hcc1 {
    flex: 0.3;
    margin: 0;
  }

  .refresh-button {
    box-shadow: 0 0 0 1px hsla(216, 33.3%, 14.7%, 0.1),
      0 1px 3px 0 hsla(216, 12.4%, 39.4%, 0.06);
    display: flex;
    flex-flow: row;
    align-items: center;
    position: relative;
    font-size: 12px;
    text-align: center;
    line-height: 14px;
    border-radius: 10px;
    text-transform: uppercase;
    background: transparent;
    height: 36px;
    border: transparent;
  }

  .rbic-img {
    display: inherit;
    margin-right: 5px;
  }

  .rb-s {
    display: flex;
    align-items: center;
    color: #717171;
  }
  .hcc2 {
    flex: 0.4;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2px;
    margin: 0;
    padding: 0;
    color: #9ba1aa;
  }

  .hcc3 {
    flex: 0.3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .ct-div {
    display: block;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    margin-left: 10px;
    position: relative;
    box-sizing: border-box;
  }
`
const ToggleCardsSpan = styled.div`
  display: block;
  background-image: url(${props => props.toggleImg});
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  cursor: pointer;

  &:hover {
    background-image: url(${props => props.toggleImgHover});
  }
`

export default function DesktopHeader({
  noOfProxies,
  cardsSize,
  setCardsToLarge,
  setCardsToList,
  reloadSubscriptions,
}) {
  return (
    <MyDesktopHeader>
      <div className="header-child">
        <div className="hc-child">
          <div className="hcc1">
            <button className="refresh-button" onClick={reloadSubscriptions}>
              <img className="rbic-img" src={reload} alt="reload-img" />
              <span className="rb-s">UPDATE</span>
            </button>
          </div>
          <h4 className="hcc2">You have {noOfProxies} active proxies</h4>
          <div className="hcc3">
            <div className="ct-div">
              <ToggleCardsSpan
                toggleImg={
                  cardsSize === "large" ? largeCardsBlue : largeCardsGrey
                }
                toggleImgHover={largeCardsBlue}
                onClick={() => setCardsToLarge()}
              ></ToggleCardsSpan>
            </div>

            <div className="ct-div">
              <ToggleCardsSpan
                toggleImg={cardsSize === "list" ? listCardsBlue : listCardsGrey}
                toggleImgHover={listCardsBlue}
                onClick={() => setCardsToList()}
              ></ToggleCardsSpan>
            </div>
          </div>
        </div>
      </div>
    </MyDesktopHeader>
  )
}

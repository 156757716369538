import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
// import moment from "moment"

import { toastSuccess, toastError } from "../utils/myToasts"
import { updateSubscriptionNickPromise } from "../utils/CallAPIs"
import { getSubscriptionFeature as getFeature } from "../store"

import renewLogo from "../imgs/renew-proxy-grey.svg"
import kilimanjaroLogo from "../imgs/kilimanjaro.svg"
import jetGearLogo from "../imgs/jet-gear-blue.svg"
import { formatDatetoEST } from "../utils/dateformat"

const MyProxyBigCard = styled.div`
  padding: 0 10px 0 10px;
  flex: none;
  display: block;

  @media (max-width: 960px) {
    width: 100%;
    padding: 0;
  }
  @media (min-width: 961px) and (max-width: 1060px) {
    width: 46%;
  }
  @media (min-width: 1061px) {
    width: 31%;
  }

  .a-container {
    text-decoration: none;
  }

  .card-container:hover {
    box-shadow: #d7e1e4 0px 0px 0px 2px,
      rgba(106, 191, 131, 0.1) 0px 0px 0px 1px,
      rgba(106, 191, 131, 0.1) 0px 30px 90px 0px,
      rgba(158, 158, 163, 0.25) 0px 2px 18px 0px;
  }
  .card-container {
    box-shadow: rgba(65, 155, 249, 0.1) 0px 0px 0px 1px,
      rgba(65, 155, 249, 0) 0px 0px 0px 0px,
      rgba(65, 155, 249, 0.08) 0px 12px 50px 0px,
      rgba(158, 158, 163, 0.25) 0px 2px 10px 0px;
    background-color: rgb(255, 255, 255);
    will-change: transform, box-shadow;
    transform: matrix(1, 0, 0, 1, 0, 0);
    display: block;
    padding: 15px 0;
    position: relative;
    min-height: 384px;
    margin: 0 auto 24px;
    border-radius: 12px;
  }

  .ccc1 {
  }

  .ccc1a {
    padding: 0 15px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
  }

  .ccc1a-span {
    margin-right: 9px;
  }

  .ccc1as-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    overflow: hidden;
    position: relative;
  }

  .ccc1asd-1 {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
  }

  .ccc1asd-1a {
    display: block;
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    position: relative;
  }

  .renew-proxy-img {
    height: 100%;
  }
  .ccc1a-para {
    color: #9ba2ac;
    font-size: 13px;
    letter-spacing: 0.3px;
    margin: 0;
    padding: 0;
  }

  .ccc1b {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }

  .ccc1b-img-div {
    margin: 20px auto;
    width: auto;
    display: table;
    position: relative;
  }

  .ccc1bid-img {
    height: 70px;
  }

  .ccc1b-input {
    color: #419bf9;
    width: 60%;
    padding: 0 10px;
    margin-bottom: 1px;
    margin-top: 0px;
    line-height: 24px;
    font-weight: 700;
    text-align: center;
    overflow: hidden;
    border: 1px solid transparent;
    font-size: 18px;
  }

  .ccc1b-input:focus {
    color: red;
  }

  .no-modem-para {
    font-size: 14px;
    text-align: center;
    margin: 20px 20px;
    min-height: 260px;
    max-height: 260px;
  }
`

const MyPti = styled.div`
  display: flex;
  flex-flow: row;
  margin: 10px 20px;
  min-height: 160px;
  max-height: 160px;

  .pti-left {
    padding: 2px;
    width: 36%;
    box-sizing: border-box;
    img {
      width: 90%;
    }
  }

  .pti-right {
    display: flex;
    flex-flow: column;
    padding: 5px;

    p {
      color: #6abf83;
      margin: 0;
      font-size: 14px;
    }

    p.font-color-black {
      color: black;
      margin: 10px 0 5px 0;
    }

    h4 {
      font-weight: 700;
      color: #419bf9;
      margin: 0 0 0 0;
    }
  }
`
const ProxyTypeInner = ({
  proxyType,
  proxyTypeServicesOffered,
  multilocationProduct,
}) => {
  let services = proxyTypeServicesOffered || ""
  if (multilocationProduct) {
    services = services.replace(
      "Dedicated Proxy Server",
      "Location Exchange Proxy"
    )
  }
  return (
    <MyPti>
      <div className="pti-left">
        <img
          src={kilimanjaroLogo}
          height="40"
          alt="proxy subscription type logo"
        />
      </div>
      <div className="pti-right">
        <h4 style={{ textTransform: "uppercase" }}>{proxyType}</h4>

        {services &&
          services.split(",").map((elem, idx) =>
            idx === 0 ? (
              <p className="font-color-black" key={idx}>
                {elem}
              </p>
            ) : (
              <p key={idx}>{elem}</p>
            )
          )}
      </div>
    </MyPti>
  )
}

const MyPdi = styled.div`
  display: flex;
  flex-flow: row;
  margin: 10px 20px;
  box-shadow: rgba(65, 155, 249, 0.1) 0px 0px 0px 1px,
    rgba(65, 155, 249, 0) 0px 0px 0px 0px,
    rgba(65, 155, 249, 0.08) 0px 12px 50px 0px,
    rgba(158, 158, 163, 0.25) 0px 2px 10px 0px;
  border-radius: 16px;
  min-height: 100px;
  // max-height: 100px;

  .pdi-left {
    padding: 10px;
  }

  .pdi-right {
    display: flex;
    flex-flow: column;
    padding: 10px;

    p {
      color: #6abf83;
      margin: 0;
      margin-bottom: 10px;
    }

    h5 {
      color: #419bf9;
      margin: 0 0 0 0;
    }

    .single-row {
      color: black;
      margin: 5px 0 5px 0;
      font-size: 14px;
    }
  }
`
const ProxyDetailInner = ({
  // dataPrioritization,
  // domainWhitelisting,
  // ipRotation,
  modem,
  pgConnection,
  multilocationProduct,
}) => {
  let httpPort = modem?.httpPort
  let ip = modem?.proxyIp
  let username = modem?.proxyUsername
  let password = modem?.proxyPassword
  if (multilocationProduct && pgConnection) {
    httpPort = pgConnection?.httpPort
    ip = pgConnection?.ip
    username = pgConnection?.username
    password = pgConnection?.password
  }
  return (
    <MyPdi>
      <div className="pdi-left">
        <img
          src={jetGearLogo}
          height="40"
          alt="proxy subscription details logo"
        />
      </div>
      {(modem || pgConnection) && (
        <div className="pdi-right">
          <div className="single-row">
            <span>HTTP Port: </span>
            <span>{httpPort}</span>
          </div>
          <div className="single-row">
            <span>Proxy IP: </span>
            <span>{ip}</span>
          </div>
          <div className="single-row">
            <span>Username: </span>
            <span>{username}</span>
          </div>
          <div className="single-row">
            <span>Password: </span>
            <span>{password}</span>
          </div>
        </div>
      )}
    </MyPdi>
  )
}

const MyManageButton = styled.button`
  background-color: #8a59ef;
  color: white;
  height: 40px;
  width: 100px;
  border-radius: 8px;
  border: 0;
  display: table;
  margin: 30px auto 10px auto;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default function ProxyBigCard(props) {
  const {
    id,
    logoLink,
    updateNickOfThisSubscription,
    modem,
    location,
    pgConnection = null,
    multilocationProduct = false,
  } = props

  const renewInDays = formatDatetoEST(props.autoRenewAt)
  const expiresInDays = formatDatetoEST(props.expiredAt)
  const showDays = !!props.autoRenewAt || !!props.expiredAt
  const proxy = getFeature(props, "Proxy Type")
  const ipRotation = getFeature(props, "IP Rotation")
  const dataPrioritization = getFeature(props, "Data Prioritization")
  const domainWhitelisting = getFeature(props, "Domain Whitelisting")
  const [nick, setNick] = useState(props.nickName || proxy.title)

  return (
    <MyProxyBigCard>
      <div className="card-container">
        <div className="ccc1">
          <div className="ccc1a">
            <span className="ccc1a-span">
              <div className="ccc1as-div">
                <div className="ccc1asd-1">
                  <div className="ccc1asd-1a">
                    <img
                      className="renew-proxy-img"
                      src={renewLogo}
                      alt="proxy to renew/expire in x days logo"
                    />
                  </div>
                </div>
              </div>
            </span>
            {showDays && (
              <p className="ccc1a-para">
                {props.autoRenewAt
                  ? "Renewal date: " + renewInDays
                  : `Expiry date: ${expiresInDays}`}
              </p>
            )}
          </div>
          <div className="ccc1b-img-div">
            <img
              className="ccc1bid-img"
              src={logoLink}
              alt="proxy subscription card logo"
            />
          </div>
          <div className="ccc1b">
            <input
              className="ccc1b-input"
              value={nick}
              onChange={e => setNick(e.target.value)}
              maxLength="12"
              onBlur={async () => {
                try {
                  if (nick === proxy.name) {
                    return
                  }
                  let res = await updateSubscriptionNickPromise(id, nick)
                  if (res.status === 200) {
                    toastSuccess(
                      "proxy name changed to " +
                        res.data.data.attributes["nick-name"]
                    )
                    updateNickOfThisSubscription(
                      res.data.data.attributes["nick-name"],
                      id
                    )
                    setNick(res.data.data.attributes["nick-name"])
                  }
                } catch (err) {
                  console.error(err)
                  toastError("failed to change proxy name...")
                }
              }}
            ></input>
            {location && (
              <strong className="small">
                {location.city}, {location.state}
              </strong>
            )}
          </div>
        </div>
        {!modem ? (
          <p className="no-modem-para">
            You have a current subscription, but your proxy server has been
            reassigned by MountProxies staff. If you think this is an error,
            please email team@mountproxies.com
          </p>
        ) : (
          <>
            <ProxyTypeInner
              proxyType={proxy.title}
              proxyTypeServicesOffered={proxy.servicesOffered}
              multilocationProduct={multilocationProduct}
            />
            <ProxyDetailInner
              modemId={modem.id}
              dataPrioritization={dataPrioritization}
              ipRotation={ipRotation}
              domainWhitelisting={domainWhitelisting}
              modem={modem}
              pgConnection={pgConnection}
              multilocationProduct={multilocationProduct}
            />
          </>
        )}
        <ActionsWrapper>
          <Link
            to={modem ? "/proxies/manage?id=" + id : "/contact-us"}
            style={{ textDecoration: "none" }}
          >
            <MyManageButton children="Manage" />
          </Link>
          {/* {!props.cryptomusRecurringPayments && (
            <MyManageButton
              className=""
              onClick={() => props.handleActiveCryptomusRecurring(id)}
            >
              Set Auto Renew
            </MyManageButton>
          )} */}
        </ActionsWrapper>
      </div>
    </MyProxyBigCard>
  )
}

import React from "react"
import Media from "react-media"

import { Subscribe } from "unstated"
import RPContainer from "../../store"
import RockPointsContainer from "../../stores/rockpoints"

import withLayout from "../../components/layout"
import DesktopHeader from "../../components/DesktopHeader"
// import MobileHeader from "../../components/MobileHeader"
import ProxiesApp from "../../components/ProxiesApp"

import loadingSpinner from "../../imgs/loading-spinner.svg"

function ProxiesPage() {
  return (
    <Subscribe to={[RPContainer, RockPointsContainer]}>
      {(rpstore, pointsStore) => {
        if (!rpstore.state.subscriptionsLoaded) {
          return (
            <div style={{ textAlign: "center" }}>
              <img src={loadingSpinner} alt="spinner" />
            </div>
          )
        }

        return (
          <React.Fragment>
            <Media
              queries={{
                small: "(max-width: 960px)",
                large: "(min-width: 961px)",
              }}
              onChange={e => {
                if (e.small) {
                  if (rpstore.state.proxiesPageCardSize !== "large")
                    rpstore.setProxiesPageCardsSizeToLarge()
                }
              }}
            />
            <DesktopHeader
              noOfProxies={rpstore.state.allSubscriptions.length}
              cardsSize={rpstore.state.proxiesPageCardSize}
              setCardsToLarge={rpstore.setProxiesPageCardsSizeToLarge}
              setCardsToList={rpstore.setProxiesPageCardsSizeToList}
              reloadSubscriptions={rpstore.reloadSubscriptions}
            ></DesktopHeader>
            {/* <MobileHeader></MobileHeader> */}
            <ProxiesApp
              proxiesPageCardSize={rpstore.state.proxiesPageCardSize}
              allSubscriptions={rpstore.state.allSubscriptions}
              setAllSubscriptions={rpstore.setAllSubscriptions}
              updateNickOfThisSubscription={
                rpstore.updateNickOfThisSubscription
              }
              setRecurringSubscriptionCryptomus={
                pointsStore.setRecurringSubscriptionCryptomus
              }
            ></ProxiesApp>
          </React.Fragment>
        )
      }}
    </Subscribe>
  )
}

export default withLayout(ProxiesPage)
